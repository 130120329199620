import styled from "styled-components"
import {
  disabledColor,
  NavigationButtonHoverTextColor,
  primaryOrange,
} from "./theme"

export const Button = styled.button`
  border-radius: 2px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: ${primaryOrange};
  color: ${NavigationButtonHoverTextColor};
  font-family: Poppins;
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  border: 0;
  text-align: center;
  padding: 0.8125rem 4.3125rem;
  transition: 200ms ease-in;
  min-width: 220px;

  @media (max-width: 600px) {
    padding: 0.5rem 1.5rem;
    min-width: max(130px, 40vw);
  }

  :focus {
    outline: none;
  }

  /*
  :disabled {
    background-color: ${disabledColor};
    box-shadow: 0px 0.2em 0px ${disabledColor};
    box-shadow: 0;
  } */
`
