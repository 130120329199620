import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import ArticlesList from "../components/articlesList"
import BackButton from "../components/backButton"
import Layout from "../components/layout"
import Navigation from "../components/navigation"
import SEO from "../components/seo"
import { Container } from "../style/container"
import { containerText } from "../style/theme"
import PropTypes from "prop-types"

const NewsHeader = styled.h1`
  font-family: Poppins;
  font-size: 1.5rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: ${containerText};
`

/**
 * @param {Object} data Translations
 */
const NewsPage = ({
  data: {
    prismic: {
      allNewspages,
      allNewss,
      allArticlesnavigations,
      allKeywordss,
      allFooters,
    },
  },
}) => {
  const {
    node: { meta_title, meta_description, meta_keywords, meta_img },
  } = allNewspages.edges[0]
  return (
    <Layout
      keywords={allKeywordss}
      footer={allFooters}
      navigation={
        <Navigation
          title={allNewspages.edges[0].node.title}
          items={allArticlesnavigations.edges[0].node.body[0].fields}
          hight={"30vh"}
        />
      }
    >
      <SEO
        meta={{
          title:
            meta_title && meta_title.length > 0 ? meta_title[0].text : null,
          description:
            meta_description && meta_description.length > 0
              ? meta_description[0].text
              : null,
          keywords:
            meta_keywords && meta_keywords.length > 0
              ? meta_keywords[0].text
              : null,
          img: !!meta_img ? meta_img.url : null,
          url: window.location.pathname,
        }}
      />
      <Container>
        <NewsHeader>{allNewspages.edges[0].node.news[0].text}</NewsHeader>
        <ArticlesList
          articles={allNewss.edges}
          filterKey={"subcategory_title"}
          filterTitle={allNewspages.edges[0].node.subcategory[0].text}
          subcategory={allNewspages.edges[0].node.subcategory[0].text}
          date={allNewspages.edges[0].node.date[0].text}
          topic={allNewspages.edges[0].node.topic[0].text}
          no_result={allNewspages.edges[0].node.no_result[0].text}
        />
        <BackButton blackcolor={true} align={"left"} />
      </Container>
    </Layout>
  )
}
NewsPage.propTypes = {
  data: PropTypes.object.isRequired,
}
export default NewsPage

export const pageQuery = graphql`
  query($locale: String!) {
    prismic {
      allNewspages(lang: $locale) {
        edges {
          node {
            title
            subcategory
            news
            date
            topic
            no_result
            meta_title
            meta_description
            meta_keywords
            meta_img
          }
        }
      }
      allArticlesnavigations(lang: $locale) {
        edges {
          node {
            body {
              ... on PRISMIC_ArticlesnavigationBodyMenu {
                fields {
                  url_text
                  url
                }
              }
            }
          }
        }
      }
      allFooters(lang: $locale) {
        edges {
          node {
            about
            aboutus
            resources
            news
            dictionary
            legal
            disclaimer
            privacypolicy
            whatwedo
            whatwedotext
            responsiblegaming
            responsiblegamingtext
            contact
            contacttext
            bottomlinks
          }
        }
      }
      allNewss(lang: $locale, sortBy: meta_firstPublicationDate_DESC) {
        edges {
          node {
            title
            _meta {
              uid
              type
              firstPublicationDate
              tags
            }
          }
        }
      }
      allKeywordss(lang: $locale) {
        edges {
          node {
            topic
            category
            date
            loadmore
            news
            categoryfilter
            glossary
            news
            aboutus
            integritypolicy
            privacypolicy
            disclaimer
            resources
            contact
            about
          }
        }
      }
    }
  }
`
