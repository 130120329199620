import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  containerText,
  NavigationButton,
  primaryOrange,
  primaryDarkText,
  NavigationButtonTextColor,
  TableGray,
} from "../style/theme"
import styled, { keyframes } from "styled-components"
import { Button } from "../style/buttons"
import { formatFullYear } from "../helpers/dates"
import { connect } from "react-redux"
import { navigate } from "gatsby"

/**
 *
 * Parent component that assambles the articles list.
 * Its used in all post views, i.e news/strategy/campaign/knowledge
 *
 * @param {Array} articles List of articles that will be shown in list
 * @param {String} filterTitle Title for table
 * @param {String} subcategory Title for category
 * @param {String} date Translation for date
 * @param {String} topic Translation for topic
 * @param {String} no_result Translation for no data
 */

const ArticlesList = ({
  articles,
  filterTitle,
  subcategory,
  date,
  topic,
  no_result,
}) => {
  const [filter, setFilter] = useState([])
  const [active, setActive] = useState([])

  useEffect(() => {
    // Define all filter buttons
    let _filter = []
    articles.forEach(article => {
      const {
        node: { _meta },
      } = article
      if (_meta.tags.length > 0) {
        _filter = _filter.concat(_meta.tags)
      }
    })

    _filter = _filter
      .filter((item, pos) => _filter.indexOf(item) === pos)
      .map(f => f.toLowerCase())

    setFilter(_filter)
    setActive(_filter)
  }, [articles])

  const handleCallback = value => {
    if (!active.includes(value)) {
      setActive([...active, value])
    } else {
      setActive(active.filter(a => a !== value))
    }
  }

  return (
    <div>
      <FilterTitle title={filterTitle} />
      <FilterRow
        handleCallback={handleCallback}
        filter={filter}
        active={active}
      />
      <ArticlesTable
        subcategory={subcategory}
        date={date}
        topic={topic}
        no_result={no_result}
        articles={articles.filter(article => {
          const {
            node: { _meta },
          } = article

          let filter = false
          if (_meta.tags) {
            _meta.tags.forEach(tag => {
              if (active.indexOf(tag.toLowerCase()) > -1) {
                filter = true
              }
            })
          }
          return filter
        })}
      />
    </div>
  )
}

ArticlesList.propTypes = {
  articles: PropTypes.array.isRequired,
  filterTitle: PropTypes.string.isRequired,
  subcategory: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  topic: PropTypes.string.isRequired,
  no_result: PropTypes.string.isRequired,
}

export default ArticlesList

const FilterHeader = styled.h2`
  font-family: Poppins;
  font-size: 1.25rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${containerText};
  margin: 1.25rem 0;
`

/**
 * @param {String} title String for filter
 */
const FilterTitle = ({ title }) => <FilterHeader>{title}</FilterHeader>
FilterTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

const FilterButton = styled(Button)`
  text-transform: capitalize;
  background-color: ${props =>
    props.isActive ? primaryOrange : NavigationButton}; /* change to var */
  color: ${props =>
    props.isActive ? primaryDarkText : NavigationButtonTextColor};
  margin: 0 1rem 1.25rem 0;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 600px) {
    margin: 0.25rem 0.8rem 0.25rem 0;
  }
`

/**
 *
 * Row with buttons representing all tags in from all articles.
 * If pressed it will send a callback to parent which button that has been pressed
 *
 * @param {Function} handleCallback Callback for pressed buttons, will toggle which is active
 * @param {Array} filter Title for table
 * @param {Array} active Title for category
 */
const FilterRow = ({ handleCallback, filter, active }) => {
  return filter.map((f, index) => {
    return (
      <FilterButton
        onClick={e => {
          e.preventDefault()
          handleCallback(f)
        }}
        isActive={active.includes(f)}
        key={index}
      >
        {f}
      </FilterButton>
    )
  })
}
FilterRow.propTypes = {
  handleCallback: PropTypes.func.isRequired,
  filter: PropTypes.array.isRequired,
  active: PropTypes.array.isRequired,
}

const TableRowEnter = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const TableContainer = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  border-spacing: 0 0.625rem;
  border-collapse: separate;
`

const TableHeaders = styled.thead`
  border: 0;
`
const TableHeader = styled.th`
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: ${primaryDarkText};
  text-transform: capitalize;

  &:first-child {
    padding-left: 3.625rem;
    width: 30%;
  }

  &:nth-child(2) {
    width: 20%;
  }

  &:last-child {
    width: 50%;
  }

  @media (max-width: 600px) {
    &:first-child {
      display: none;
    }

    &:nth-child(2) {
      padding-left: 1rem;
      width: 30%;
    }

    &:last-child {
      width: 70%;
    }
  }
`

const TableRow = styled.tr`
  animation-delay: ${props => props.delay.toString() + "ms"};
  opacity: 0;
  animation-name: ${TableRowEnter};
  animation-duration: 400ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  border: solid 1px ${NavigationButton};
  background-color: ${props => (props.bgColor ? NavigationButton : TableGray)};
  cursor: pointer;
  transition: 200ms ease-in;

  :hover {
    color: ${primaryDarkText};
    background: ${primaryOrange};
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
`

const TableCell = styled.th`
  font-family: Lato;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  padding: 0.8125rem 0rem;
`
const TableCellType = styled(TableCell)`
  padding-left: 3.625rem;
  text-transform: capitalize;

  @media (max-width: 600px) {
    display: none;
  }
`

const TableCellDate = styled(TableCell)`
  @media (max-width: 600px) {
    padding-left: 1rem;
  }
`

const TableCellTitle = styled(TableCell)`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :first-letter {
    text-transform: uppercase;
  }
`

const mapStateToProps = state => {
  return { language: state.language }
}

const NoText = styled(FilterHeader)`
  text-align: center;
  margin-top: 3rem;
`

/**
 *
 * Articles shown in table is filted depending on which tags that are active
 *
 * @param {Array} articles List of articles that will be shown in list
 * @param {String} subcategory Title for category
 * @param {String} date Translation for date
 * @param {String} topic Translation for topic
 * @param {String} no_result Translation for no data
 * @param {String} language object from redux, showing current language
 */
const ArticlesTable = connect(
  mapStateToProps,
  null
)(({ articles, subcategory, date, topic, language, no_result }) => {
  if (articles.length === 0) return <NoText>{no_result}</NoText>

  const handleClick = (e, _meta) => {
    const { type, uid } = _meta
    const { path } = language
    navigate(`/${path}/${type}/${uid}`)
  }

  return (
    <div style={{ marginTop: "1.5rem" }}>
      <TableContainer>
        <TableHeaders>
          <tr>
            <TableHeader>{subcategory}</TableHeader>
            <TableHeader>{date}</TableHeader>
            <TableHeader>{topic}</TableHeader>
          </tr>
        </TableHeaders>
        <tbody>
          {articles.map((article, index) => {
            const { node } = article
            const { title, _meta } = node
            const { firstPublicationDate } = node._meta

            let sub = []
            if (_meta.tags) {
              sub = _meta.tags.join(", ")
            }

            return (
              <TableRow
                key={`articles-${index}`}
                bgColor={!!(index % 2)}
                delay={index * 50}
                onClick={e => handleClick(e, _meta)}
              >
                <TableCellType>{sub}</TableCellType>
                <TableCellDate>
                  {formatFullYear(language.locale, firstPublicationDate)}
                </TableCellDate>
                <TableCellTitle>{title[0].text}</TableCellTitle>
              </TableRow>
            )
          })}
        </tbody>
      </TableContainer>
    </div>
  )
})
ArticlesTable.propTypes = {
  articles: PropTypes.array.isRequired,
  subcategory: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  topic: PropTypes.string.isRequired,
  no_result: PropTypes.string.isRequired,
  language: PropTypes.object,
}
